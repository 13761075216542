import * as Base from '../base';
import * as Styled from './styled';

import { Col, Row } from 'react-flexbox-grid';

import FooterLink from './FooterLink';
import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  background: ${props => props.theme.colors.rangoonGreen};
  padding: 9rem 0 4rem;
`;

const Footer = () => {
  return (
    <Container>
      <Base.Container>
        <Row start="xs" top="xs">
          <Col xs={12} lg={4} xl={3}>
            <FooterLink
              title="ELI Project"
              url="http://eliproject.proecclesia.pl"
              subtitle={
                <>
                  Eli project a ministry of ProEcclesia.
                  <br />
                  <strong>Game Concept</strong> Nate Berry
                </>
              }
            />
          </Col>
          <Col xs={12} lg={4} xl={3}>
            <FooterLink
              title="IGGAMES"
              url="https://www.iggames.co"
              subtitle={
                <>
                  <strong>Game Design</strong> Igor Widawski
                </>
              }
            />
          </Col>
          <Col xs={12} lg={4} xl={3}>
            <FooterLink
              title="01Unit"
              url="http://kamgrus.pl"
              subtitle={
                <>
                  <strong>Graphic Design</strong> Tytus Gruszczyński
                </>
              }
            />
          </Col>
        </Row>
        <Row start="xs" middle="xs">
          <Col xs={12} lg={4}>
            <Styled.Copyrights>
              © 2018. ProEcclesia, IGGAMES, 01UNIT
              <br />
              <span
                css={`
                  font-family: IM Fell English, serif;
                `}
              >
                All Rights Reserved.
              </span>
            </Styled.Copyrights>
          </Col>
        </Row>
      </Base.Container>
    </Container>
  );
};

export default Footer;
