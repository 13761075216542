import * as Base from './base';

import { Col, Row } from 'react-flexbox-grid';

import React from 'react';
import Trees from '../assets/treetreetree.inline.svg';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

const Text1 = styled.div`
  font-family: Barlow;
  font-weight: bold;
  color: ${props => props.theme.colors.schist};

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.7rem;
  }

  @media ${props => props.theme.breakpoints.sm} {
    display: inline-block;
    font-size: 2rem;
    margin-right: 0.5rem;
  }
`;

const Text2 = styled.div`
  font-family: IM Fell English, serif;
  font-weight: normal;
  color: ${props => props.theme.colors.schist};

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.6rem;
  }

  @media ${props => props.theme.breakpoints.sm} {
    display: inline-block;
    font-size: 2rem;
    margin-right: 2.5rem;
  }
`;

const WebsiteLink = styled.a`
  border: 0.2rem solid ${props => props.theme.colors.kelp};
  color: ${props => props.theme.colors.schist};
  padding: 0.7rem 1.4rem;
  display: none;

  @media ${props => props.theme.breakpoints.lg} {
    display: block;
  }
`;

const TreesContainer = styled.div`
  @media ${props => props.theme.breakpoints.xs} {
    padding-right: 1rem;
  }

  @media ${props => props.theme.breakpoints.md} {
    padding-right: 2rem;
  }
`;

const TreesIcon = styled(Trees)`
  height: auto;

  @media ${props => props.theme.breakpoints.xs} {
    width: 3.8rem;
  }

  @media ${props => props.theme.breakpoints.md} {
    width: 4.5rem;
  }
`;

const Header = () => {
  const intl = useIntl();

  return (
    <header>
      <Base.Container>
        <Row
          start="xs"
          middle="xs"
          css={`
            padding: 1.2rem 0 0.8rem;
          `}
        >
          <Col>
            <TreesContainer>
              <TreesIcon />
            </TreesContainer>
          </Col>
          <Col>
            <Text1>{intl.formatMessage({ id: 'header.phrase1' })}</Text1>
            <Text2>Forest Planting Colaboration Center</Text2>
          </Col>
          <Col>
            <WebsiteLink rel="noopener noreferrer" target="_blank" href="https://google.com">
              Check our website for ongoing training resources
            </WebsiteLink>
          </Col>
        </Row>
      </Base.Container>
    </header>
  );
};

export default Header;
